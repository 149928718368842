<template>
  <v-row justify="center" style="z-index: 9999">
    <v-dialog v-model="dialog" max-width="80%" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          {{ $t("pricing.calculator") }}
          <v-icon right>mdi-calculator</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5 mr-auto">
            {{ $t("pricing.pricing_calculator") }}</span
          >
          <v-btn icon @click="dialog = false">x</v-btn>

          <v-row class="mt-3">
            <v-col cols="12" sm="6" md="2">
              <v-select
                flat
                ref="tyreType"
                :items="translatedTyreTypes"
                v-model="form.tyreType"
                :label="$t('pricing.tyre_type')"
                class="to-upper"
                item-value="id"
                item-text="text"
                hide-no-data
                @change="calculate()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-select
                flat
                ref="tyreSeason"
                :items="translatedTyreSeasons"
                v-model="form.tyreSeason"
                :label="$t('pricing.tyre_season')"
                class="to-upper"
                item-value="id"
                item-text="text"
                required
                hide-no-data
                @change="calculate()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-select
                flat
                ref="rimSize"
                :items="rimSizes"
                v-model="form.rimSize"
                :label="$t('pricing.rim_size')"
                class="to-upper"
                item-value="value"
                item-text="value"
                required
                hide-no-data
                @change="calculate()"
              />
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-text-field
                :label="$t('pricing.price')"
                v-model="form.price"
                required
                @input="debounceHandler()"
                v-on:keydown="handleInput($event, 'float', 50)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              class="d-flex flex-row justify-space-between"
            >
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on"> -->
              <v-checkbox
                v-model="form.rft"
                @change="calculate()"
                label="RFT"
              ></v-checkbox>
              <!-- </span>
                </template>
                <span>RFT Explanation Goes Here</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on"> -->
              <v-checkbox
                v-model="form.fitting"
                @change="computePrice()"
                :label="$t('pricing.fitting')"
              ></v-checkbox>
              <!-- </span>
                </template> -->
              <!-- <span>Montage Explanation Goes Here </span> -->
              <!-- </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on"> -->
              <v-checkbox
                v-model="filter.vat"
                @change="computePrice()"
                label="VAT"
              ></v-checkbox>
              <!-- </span>
                </template>
                <span>VAT Explanation Goes Here</span>
              </v-tooltip> -->
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-row>
                <v-col cols="12" style="overflow: auto">
                  <v-data-table
                    :loading="isLoading"
                    :hide-default-footer="true"
                    :headers="headings"
                    :items="computedPrice"
                    :items-per-page="itemsPerPage"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr :style="computeRowBg(index)">
                        <td>{{ item.family_client }}</td>
                        <td>
                          {{ item.amount }}
                        </td>
                        <td>{{ item.price }}</td>
                        <td>{{ item.price_2 }}</td>
                        <td>{{ item.price_3 }}</td>
                        <td>{{ item.price_4 }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="dialog = false">
            {{ $t("pricing.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { debounce } from "lodash";
export default {
  data() {
    return {
      dialog: false,
      form: {},
      filter: {},
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      pricingRules: [],
      computedPrice: [],
      fittingRate: 0,
      isLoading: false,
      headings: [
        { text: "Family Client" },
        { text: "P.HTVA" },
        { text: "1 Tyre" },
        { text: "2 Tyres" },
        { text: "3 Tyres" },
        { text: "4 Tyres" },
      ],
      translatedTyreTypes: [],
      translatedTyreSeasons: [],
    };
  },
  async created() {
    if (this.tyreTypes.length === 0) {
      await this.getTyreTypes().then(() => this.translateTyreTypes());
    } else {
      this.translateTyreTypes();
    }

    if (this.tyreSeasons.length === 0) {
      await this.getTyreSeasons().then(() => this.translateTyreSeasons());
    } else {
      this.translateTyreSeasons();
    }

    // Set Default Values
    this.form.tyreType = this.translatedTyreTypes[0].id;
    this.form.tyreSeason = this.translatedTyreSeasons[1].id;
    this.form.rimSize = this.rimSizes[0].value;

    this.filter.vat = true;
    this.form.fitting = true;

    // Start Debounce
    this.debounceHandler = debounce((event) => this.calculate(), 500);
  },
  beforeUnmount() {
    this.debouncedHandler.cancel();
  },

  computed: {
    // Rim Sizes Generate Number from 11 to 30 in array assigned to a variable
    rimSizes() {
      let rimSizes = [];
      for (let i = 10; i <= 30; i++) {
        rimSizes.push({ value: i });
      }
      return rimSizes;
    },
  },
  methods: {
    translateTyreTypes() {
      this.translatedTyreTypes = this.translateKeys(
        this.tyreTypes,
        "key",
        "types"
      );
    },
    translateTyreSeasons() {
      this.translatedTyreSeasons = this.translateKeys(
        this.tyreSeasons,
        "key",
        "seasons"
      );
    },
    async calculate() {
      if (!this.form.price || parseFloat(this.form.price) <= 0) {
        this.computedPrice = [];

        return this.$noty.error(this.$t("pricing.price_error"), {
          layout: "bottomLeft",
          timeout: 5000,
        });
      }
      if (!this.form.tyreType) {
        return this.$noty.error(this.$t("pricing.tyre_type_error"), {
          layout: "bottomLeft",
          timeout: 5000,
        });
      }

      this.isLoading = true;
      this.computedPrice = [];
      this.fittingRate = await this.getFittingRate();

      axios
        .get(this.generateUrl(), {
          headers: this.header,
        })
        .finally(() => (this.isLoading = false))
        .then(({ data }) => {
          this.pricingRules = data.data;
          this.computePrice();
        });
    },
    generateUrl(fitting = false) {
      let url =
        API_BASE_URL +
        "/pricing_rules?family_article_id=51&condition_id=10231000";
      if (fitting) {
        url += "&rule_type_id=10081003";
      } else {
        url += "&rule_type_id=10081000";
      }
      if (this.form?.tyreType) {
        url += "&property_01=" + this.form.tyreType;
      }
      if (this.form?.tyreSeason && !fitting) {
        url += "&property_02=" + this.form.tyreSeason;
      }
      if (this.form?.rimSize) {
        url += "&property_05=" + this.form.rimSize;
      }
      if (this.form?.rft === true) {
        url += "&property_14=1";
      }

      return url;
    },
    getFittingRate() {
      return new Promise((resolve, reject) => {
        axios
          .get(this.generateUrl(true), {
            headers: this.header,
          })
          .then(({ data }) => {
            resolve(data.data[0]?.company_article?.price || 0);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async computePrice() {
      this.computedPrice = this.pricingRules.map((item) => {
        let price = parseFloat(item.amount) + parseFloat(this.form.price || 0);

        let totalCostPrice = price;

        if (this.form.fitting) {
          price += parseFloat(this.fittingRate);
        }

        if (this.filter.vat) {
          let vatRate = item.company?.tva_detail?.tax || null;
          vatRate = parseFloat(vatRate);
          if (vatRate && vatRate > 0) {
            price *= vatRate / 100 + 1;
          }
        }

        return {
          family_client: item.familyClient?.name || "",
          amount: totalCostPrice.toFixed(2),
          price: price.toFixed(2),
          price_2: (price * 2).toFixed(2),
          price_3: (price * 3).toFixed(2),
          price_4: (price * 4).toFixed(2),
        };
      });
    },
    computeRowBg(index) {
      // Get Middle Index of Computed Price
      let middleIndex = Math.floor(this.computedPrice.length / 2);
      if ([middleIndex - 2, middleIndex + 2].includes(index)) {
        return `background:` + this.$store.state.colorCodes.warning;
      }

      if (middleIndex == index) {
        return `background:` + this.$store.state.colorCodes.danger;
      }

      return "";
    },
    getTyreTypes() {
      return axios
        .get(API_BASE_URL + "/codes?class_key=tyre_types", {
          headers: { Authorization: "Bearer " + this.$store.state.AccessToken },
        })
        .then(({ data }) => {
          this.$store.commit("setTyreTypes", data.data);
        });
    },
    getTyreSeason() {
      return axios
        .get(API_BASE_URL + "/codes?class_key=tyre_season", {
          headers: { Authorization: "Bearer " + this.$store.state.AccessToken },
        })
        .then(({ data }) => {
          this.$store.commit("setTyreSeasons", data.data);
        });
    },
  },
};
</script>
